export function DateAndHour(dt_chegada) {
    if (typeof dt_chegada !== 'string') {
        return '-- / --';
    }

    const match = dt_chegada.match(/^(\d{4})-(\d{2})-(\d{2})T(\d{2}):(\d{2}):(\d{2}).*/);

    if (match) {
        const data_formatada = `${match[3]}/${match[2]} às ${match[4]}:${match[5]}`;
        return data_formatada;
    } else {
        return '-- / --';
    }
}

export function formatDateAndTime(tempo_previsto_termino) {
    if (typeof tempo_previsto_termino !== 'string') {
        return '-- / --';
    }

    try {
        const date = new Date(tempo_previsto_termino);
        const day = String(date.getDate()).padStart(2, '0'); // Data no fuso horário correto
        const month = String(date.getMonth() + 1).padStart(2, '0'); // Mês (0 indexado)
        const hours = String(date.getHours()).padStart(2, '0'); // Hora no fuso horário
        const minutes = String(date.getMinutes()).padStart(2, '0'); // Minutos

        return `${day}/${month} às ${hours}:${minutes}`;
    } catch (error) {
        return '-- / --'; // Retorno padrão em caso de erro
    }
}

export function DateAndHourPreview(dt_chegada) {
    if (typeof dt_chegada !== 'string') {
        return '-- / --';
    }

    const match = dt_chegada.match(/^(\d{4})-(\d{2})-(\d{2})T(\d{2}):(\d{2}):(\d{2}).*/);

    if (match) {
        const date = new Date(dt_chegada);
        const day = String(date.getDate()).padStart(2, '0'); // Horário local
        const month = String(date.getMonth() + 1).padStart(2, '0'); // Horário local
        const hours = String(date.getHours()).padStart(2, '0'); // Horário local
        const minutes = String(date.getMinutes()).padStart(2, '0'); // Horário local

        const data_formatada = `${day}/${month} às ${hours}:${minutes}`;
        return data_formatada;
    } else {
        return '-- / --';
    }
}
