import { Button, Card, Grid, Menu, MenuItem, TablePagination, TextField, Typography } from '@mui/material';
import { Box } from '@mui/system';
import React from 'react';
import { gridSpacing } from 'store/constant';
import MainCard from 'ui-component/cards/MainCard';
import { DataGrid } from '@mui/x-data-grid';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import ReportGmailerrorredIcon from '@mui/icons-material/ReportGmailerrorred';
import { useNavigate } from 'react-router';
import ActionButtons from './components/ActionButtons';
import { useApi } from 'Service/axios';
import { useState } from 'react';
import { useEffect } from 'react';
import formater from 'utils/formatDate';
import ConfirmRequest from './components/ConfirmRequest';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { formatarData } from 'utils/regex';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { format } from 'date-fns';
import SkeletonTable from 'ui-component/SkeletonTable';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import AirIcon from '@mui/icons-material/Air';
import TimelapseIcon from '@mui/icons-material/Timelapse';
import HoverSocialCard from './components/HoverSocialCard';
import { set } from 'store';

const Programming = () => {
    const api = useApi();
    const navigate = useNavigate();

    const [dataRows, setDataRows] = useState([]);
    const [openConfirmRequest, setOpenConfirmRequest] = useState(false);
    const [currentRow, setCurrentRow] = useState(null);
    const [horaAtualizada, setHoraAtualizada] = useState('');
    const [previsaoHora, setPrevisaoHora] = useState('');

    function determinarCor(currentItem) {
        const statusSetorArray = currentItem.agrupamentos.map((item) => {
            return item.status_setor;
        });

        // console.log('Status Setor:', statusSetorArray);

        const todosEP = statusSetorArray.every((status) => status === 'EP');
        const peloMenosUmEF = statusSetorArray.some((status) => status === 'EF');
        const todosFA = statusSetorArray.every((status) => status === 'FA');

        if (todosEP) {
            return '#FF6961';
        } else if (peloMenosUmEF) {
            return '#FFD700';
        } else if (todosFA) {
            return '#8FBC8F';
        }

        return '#FFD700';
    }

    const getData = async () => {
        try {
            const res = await api.getAllExpedition();

            const data = res.data;

            const rows = data?.map((item) => ({
                ...item,
                id: item.id,
                cod: item.id,
                agrupamento: item.agrupamento,

                cliente: item.cliente,

                obra: item.obra
            }));
            console.log('🚀 ~ file: index.js:84 ~ rows ~ rows:', rows);

            const sortedRows = rows.sort((a, b) => b.id - a.id);

            setDataRows(sortedRows);
        } catch (error) {
            console.log('🚀 ~ file: index.js:146 ~ getData ~ error:', error);
        }
    };

    const getAtualizationHour = async () => {
        try {
            const res = await api.getAtualizationHour();

            const data = res?.data;

            setHoraAtualizada(data?.ultima_atualizacao);
            setPrevisaoHora(data?.proxima_atualizacao);
        } catch (error) {
            console.log('🚀 ~ file: index.js:146 ~ getData ~ error:', error);
        }
    };

    useEffect(() => {
        getData();
        getAtualizationHour();
    }, []);
    //collapse
    const [openCollapse, setOpenCollapse] = useState(Array(dataRows.length).fill(false));

    //menu de ações

    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    // área relacionada apenas a filtros
    const [selectedField, setSelectedField] = useState('');
    const [searchTerm, setSearchTerm] = useState('');
    const [filteredResults, setFilteredResults] = useState([]);

    useEffect(() => {
        setFilteredResults(dataRows);
    }, [dataRows]);

    const handleFieldChange = (event) => {
        const newSelectedField = event.target.value;
        setSelectedField(newSelectedField);
        filterResults(newSelectedField, searchTerm);
    };

    const handleSearchChange = (event) => {
        const newSearchTerm = event.target.value;
        setSearchTerm(newSearchTerm);
        filterResults(selectedField, newSearchTerm);
    };

    const filterResults = (field, term) => {
        const results = dataRows.filter((item) => {
            const fieldItem = field.toLowerCase();
            const fieldValue = item[fieldItem];

            if (fieldValue !== undefined) {
                const fieldValueString = String(fieldValue).toLowerCase();

                return fieldValueString.includes(term.toLowerCase());
            }

            return false;
        });
        console.log('🚀 ~ file: index.js:108 ~ results ~ results:', results);

        setFilteredResults(results);
    };

    const selectTypes = ['Gepro', 'Obra', 'Cliente'];

    //area relacionada apenas a paginação
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(25);

    const [currentRows, setCurrentRows] = useState([]);

    const [totalRed, setTotalRed] = useState(0);
    const [totalYellow, setTotalYellow] = useState(0);
    const [totalGreen, setTotalGreen] = useState(0);
    useEffect(() => {
        setTotalRed(0);
        setTotalYellow(0);
        setTotalGreen(0);

        const indexOfLastRow = (page + 1) * rowsPerPage;
        const indexOfFirstRow = indexOfLastRow - rowsPerPage;
        setCurrentRows(filteredResults.slice(indexOfFirstRow, indexOfLastRow));
    }, [filteredResults, page, rowsPerPage]);

    return (
        <MainCard title="Programações de expedição" sx={{ position: 'relative' }}>
            {/* Container para os cards */}
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'flex-start',
                    gap: 2,
                    position: 'absolute',
                    top: 1,
                    right: 16
                }}
            >
                {/* Card de última atualização */}
                <Card sx={{ mb: 2, width: 200, padding: 2, boxShadow: 2 }}>
                    <Typography variant="h6" color="textSecondary">
                        Última Atualização em:
                    </Typography>
                    <Typography variant="body1" color="textPrimary">
                        {horaAtualizada || 'Carregando...'}
                    </Typography>
                </Card>

                {/* Card de próxima atualização */}
                <Card sx={{ width: 200, padding: 2, boxShadow: 2 }}>
                    <Typography variant="h6" color="textSecondary">
                        Atualização Prevista Até:
                    </Typography>
                    <Typography variant="body1" color="textPrimary">
                        {previsaoHora || 'Carregando...'}
                    </Typography>
                </Card>
            </Box>
            <ConfirmRequest open={openConfirmRequest} setOpen={setOpenConfirmRequest} data={currentRow} getData={getData} />
            <Grid container spacing={gridSpacing}>
                <Box sx={{ display: 'flex', position: 'absolute', top: '10px', left: '50%', transform: 'translateX(-62%)' }}>
                    <TextField
                        sx={{ width: '170px', borderRight: 'none' }}
                        onChange={(e) => handleFieldChange(e)}
                        variant="filled"
                        select
                        label="Selecione um filtro"
                    >
                        {selectTypes.map((option, index) => (
                            <MenuItem key={index} value={option}>
                                {option}
                            </MenuItem>
                        ))}
                    </TextField>
                    <TextField
                        variant="filled"
                        onChange={(e) => handleSearchChange(e)}
                        sx={{ width: '250px', marginLeft: '-2px' }}
                        label="Pesquise ..."
                    />
                </Box>
                {currentRows.length > 0 ? (
                    <>
                        <TableContainer component={Paper} sx={{ maxHeight: 'calc(100vh - 330px)', overflow: 'auto' }}>
                            <Table size="small" aria-label="collapsible table" stickyHeader>
                                <TableHead>
                                    <TableRow>
                                        <TableCell align="center">Gepro</TableCell>
                                        <TableCell align="center">Cliente</TableCell>
                                        <TableCell align="center">Obra</TableCell>
                                        <TableCell align="center">Ações</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {currentRows.map((row, indexRow) => (
                                        <>
                                            <TableRow key={indexRow} sx={{ '& > *': { borderBottom: 'unset' } }}>
                                                <TableCell align="center" sx={{ fontSize: '14px' }}>
                                                    {row.gepro}
                                                </TableCell>
                                                <TableCell align="center">{row.cliente}</TableCell>
                                                <TableCell align="center">{row.obra}</TableCell>

                                                <TableCell align="center">
                                                    <div>
                                                        <IconButton
                                                            title="consultar"
                                                            onClick={() => navigate(`/main/expedicao/agrupamentos/${row.gepro}`)}
                                                        >
                                                            <AccessTimeIcon />
                                                        </IconButton>
                                                    </div>
                                                </TableCell>
                                            </TableRow>
                                        </>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                        <TablePagination
                            rowsPerPageOptions={[25, 50, 100]}
                            component="div"
                            count={filteredResults.length}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onPageChange={(e, newPage) => setPage(newPage)}
                            onRowsPerPageChange={(e) => {
                                setRowsPerPage(parseInt(e.target.value, 10));
                                setPage(0);
                            }}
                        />
                    </>
                ) : (
                    <>
                        {dataRows.length === 0 && <SkeletonTable />}
                        {currentRows.length === 0 && <p style={{ marginLeft: '20px' }}>Sem Dados</p>}
                    </>
                )}
            </Grid>
        </MainCard>
    );
};

export default Programming;
