import { Box, Button, Chip, Grid } from '@mui/material';
import { gridSpacing } from 'store/constant';
import MainCard from 'ui-component/cards/MainCard';
import { DataGrid, gridClasses } from '@mui/x-data-grid';
import ScaleIcon from '@mui/icons-material/Scale';
import CardDark from './components/CardDark';
import { CardArea, CardWrapper } from './styles';
import BlurOnIcon from '@mui/icons-material/BlurOn';
import LocalAtmIcon from '@mui/icons-material/LocalAtm';
import FilterModal from './components/FilterModal';
import { useApi } from 'Service/axios';
import { useEffect, useState } from 'react';
import { Stack, style, textAlign } from '@mui/system';
import { ModalLoadAnimated } from 'ui-component/ModalLoad';
import FilterCard from './components/FilterCard';
import { toast } from 'react-toastify';
import { formatacaoData } from 'utils/formatacaoData';
import { he } from 'date-fns/locale';

export function PainelGestaoObras() {
    const api = useApi();
    const [rows, setRows] = useState([]);
    const [isActiveFilter, setIsActiveFilter] = useState(false);
    const [filteredData, setFilteredData] = useState([]);
    const [filteredTotal, setFilteredTotal] = useState([]);
    const [load, setLoad] = useState(false);

    const [orderFilter, setOrderFilter] = useState(null);
    const [initialDataFilter, setInitialDataFilter] = useState(null);
    const [finalDataFilter, setFinalDataFilter] = useState(null);
    const [geproFilter, setGeproFilter] = useState(null);

    const parseDate = (dateString) => {
        const [day, month, year] = dateString.split('/').map(Number);
        return new Date(year, month - 1, day); // Subtraia 1 do mês porque `Date` usa índice 0 para meses.
    };

    const columns = [
        {
            field: 'gepro',
            headerName: 'GEPRO',
            width: 80,
            align: 'center',
            headerAlign: 'center',
            headerClassName: 'super-app-theme--header',
            valueGetter: (params) => params?.row?.gepro ?? '-'
        },
        {
            field: 'pedido',
            headerName: 'PDV',
            width: 80,
            headerClassName: 'super-app-theme--header',
            valueGetter: (params) => params?.row.pedido ?? '-'
        },
        {
            field: 'cliente',
            headerName: 'CLIENTE',
            width: 200,
            headerClassName: 'super-app-theme--header',
            valueGetter: (params) => params?.row.cliente ?? '-'
        },
        {
            field: 'obra',
            headerName: 'OBRA',
            width: 200,
            headerClassName: 'super-app-theme--header',
            valueGetter: (params) => params?.row?.obra ?? '-'
        },
        {
            field: 'perc_prog',
            headerName: '% PROGRAMADA',
            width: 120,
            align: 'center',
            headerAlign: 'center',
            headerClassName: 'super-app-theme--header',
            valueGetter: (params) =>
                params?.row.perc_prog !== null && !isNaN(params?.row.perc_prog) ? `${Number(params?.row.perc_prog).toFixed(0)}%` : '-'
        },
        {
            field: 'perc_prod',
            headerName: '% FABRICADA',
            width: 100,
            align: 'center',
            headerAlign: 'center',
            headerClassName: 'super-app-theme--header',
            valueGetter: (params) =>
                params?.row.perc_prod !== null && !isNaN(params?.row.perc_prod) ? `${Number(params?.row.perc_prod).toFixed(0)}%` : '-'
        },
        {
            field: 'paint_type',
            headerName: 'PINTURA',
            width: 100,
            headerClassName: 'super-app-theme--header',
            valueGetter: (params) => params?.row.paint_type ?? '-'
        },
        {
            field: 'perc_exp',
            headerName: '% EXPEDIDA',
            width: 100,
            align: 'center',
            headerAlign: 'center',
            headerClassName: 'super-app-theme--header',
            valueGetter: (params) =>
                params?.row.perc_exp !== null && !isNaN(params?.row.perc_exp) ? `${Number(params?.row.perc_exp).toFixed(0)}%` : '-'
        },
        {
            field: 'status_gepro',
            headerName: 'SITUAÇÃO GEPRO',
            width: 200,
            headerClassName: 'super-app-theme--header',
            valueGetter: (params) => params?.row.status_gepro ?? '-'
        },
        {
            field: 'data_entrega',
            headerName: 'DATA DE ENTREGA',
            width: 100,
            headerClassName: 'super-app-theme--header',
            valueGetter: (params) => params?.row.data_entrega ?? '-'
        },
        {
            field: 'reprogramming',
            headerName: 'PRAZO DE REPROGRAMADO',
            width: 150,
            headerClassName: 'super-app-theme--header',
            valueGetter: (params) => params?.row.reprogramming ?? '-'
        },
        {
            field: 'obs_pcp',
            headerName: 'OBSERVAÇÃO PCP',
            width: 300,
            headerClassName: 'super-app-theme--header',
            valueGetter: (params) => params?.row.obs_pcp ?? '-'
        },
        {
            field: 'obs_expedicao',
            headerName: 'OBSERVAÇÃO EXPEDIÇÃO',
            width: 300,
            headerClassName: 'super-app-theme--header',
            valueGetter: (params) => params?.row.obs_expedicao ?? '-'
        },
        {
            field: 'obs_codificador',
            headerName: 'OBSERVAÇÃO CODIFICADOR',
            width: 300,
            align: 'center',
            headerAlign: 'center',
            headerClassName: 'super-app-theme--header',
            valueGetter: (params) => params?.row.obs_codificador ?? '-'
        },
        {
            field: 'setor',
            headerName: 'SETOR',
            width: 100,
            headerClassName: 'super-app-theme--header',
            valueGetter: (params) => params?.row.setor ?? '-'
        },
        {
            field: 'vendedor',
            headerName: 'VENDEDOR',
            width: 100,
            headerClassName: 'super-app-theme--header',
            valueGetter: (params) => params?.row.vendedor ?? '-'
        },
        {
            field: 'frete',
            headerName: 'FRETE',
            width: 100,
            headerClassName: 'super-app-theme--header',
            valueGetter: (params) => params?.row.frete ?? '-'
        },
        {
            field: 'packing',
            headerName: 'EMBALAGEM',
            width: 100,
            headerClassName: 'super-app-theme--header',
            valueGetter: (params) => params?.row.packing ?? '-'
        },
        {
            field: 'username',
            headerName: 'CODIFICADOR',
            width: 130,
            headerClassName: 'super-app-theme--header',
            valueGetter: (params) => params?.row.username ?? '-'
        },
        {
            field: 'in_hold',
            headerName: 'IN HOLD',
            width: 120,
            align: 'center',
            headerAlign: 'center',
            headerClassName: 'super-app-theme--header',
            valueGetter: (params) => (params.row.in_hold === 1 ? '✅' : '-')
        },
        {
            field: 'peso_bruto',
            headerName: 'PESO BRUTO',
            width: 120,
            align: 'center',
            headerAlign: 'center',
            headerClassName: 'super-app-theme--header',
            valueGetter: (params) => {
                const peso = params?.row.peso_bruto;
                if (peso === null || peso === undefined) {
                    return '-';
                }
                return Number(peso).toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 });
            }
        }
    ];

    const formatterkg = new Intl.NumberFormat('pt-BR', { minimumFractionDigits: 2 });

    const formatter = new Intl.NumberFormat('pt-BR', {
        style: 'currency',
        currency: 'BRL',
        notation: 'compact'
    });

    const formatterStandat = new Intl.NumberFormat('pt-BR', {
        style: 'currency',
        currency: 'BRL'
    });

    async function getGestaoObras() {
        setLoad(true);
        try {
            const { data } = await api.getAllGestaoObrasView();

            const row = data.map((item, index) => ({
                ...item,
                id: index,
                perc_exp: item.perc_exp * 100,
                perc_prod: item.perc_prod * 100,
                perc_prog: item.perc_prog * 100
            }));

            const orderByGepro = row.sort((a, b) => b.gepro - a.gepro);

            setRows(orderByGepro);
        } catch (error) {
            console.log('🚀 ~ getGestaoObras ~ error:', error);
        } finally {
            setLoad(false);
        }
    }

    useEffect(() => {
        getGestaoObras();
    }, []);

    useEffect(() => {
        if (isActiveFilter) {
            if (!filteredData.length) {
                setIsActiveFilter(false);
                toast.error('Nenhum dado encontrado com os filtros aplicados');
                return;
            }

            const rowFiltered = filteredData.map((item, index) => ({
                ...item,
                id: index,
                perc_exp: item.perc_exp * 100,
                perc_prod: item.perc_prod * 100,
                perc_prog: item.perc_prog * 100
            }));

            console.log('🚀 ~ row resultado ~ row:', rowFiltered);
            setRows(rowFiltered);
        }

        // return () => {
        //     console.log('Função de limpeza executada');
        //     // Código de limpeza (opcional)
        // };
    }, [isActiveFilter, filteredData, filteredTotal]);

    function handleClearFilter() {
        setIsActiveFilter(false);
        getGestaoObras();
    }

    return (
        <MainCard title={'Gestão de Obras'}>
            <Grid container spacing={gridSpacing} sx={{ position: 'relative' }}>
                {load && <ModalLoadAnimated open={load} />}

                <div style={{ display: 'flex', gap: '20px', alignItems: 'center', position: 'absolute', right: 0, top: '-55px' }}>
                    {/* {isActiveFilter && filteredData.length > 0 && (
                        <div style={{ position: 'absolute', right: '270px', top: '-15px' }}>
                            <FilterCard project={orderFilter} gepro={geproFilter} />
                        </div>
                    )} */}

                    <FilterModal
                        data={rows}
                        setFilteredTotal={setFilteredTotal}
                        setFilteredData={setFilteredData}
                        setIsActiveFilter={setIsActiveFilter}
                        setLoad={setLoad}
                        setGeproFilter={setGeproFilter}
                        setOrderFilter={setOrderFilter}
                    />
                    {isActiveFilter && (
                        <Stack direction="row" spacing={1}>
                            <Chip color="error" variant="filled" label="Limpar Filtros" onDelete={handleClearFilter} />
                        </Stack>
                    )}
                </div>
                <Box sx={{ width: '100%', overFlow: 'auto', marginTop: '20px' }}>
                    {rows.length > 0 && (
                        <DataGrid
                            rows={rows}
                            columns={columns}
                            // getRowId={(row) => row.gepro}
                            sx={{
                                '&.MuiDataGrid-root--densityCompact .MuiDataGrid-cell': {
                                    py: 1
                                },
                                '&.MuiDataGrid-root--densityStandard .MuiDataGrid-cell': {
                                    py: '15px'
                                },
                                '&.MuiDataGrid-root--densityComfortable .MuiDataGrid-cell': {
                                    py: '22px'
                                },
                                '& .super-app-theme--header': {
                                    backgroundColor: '#D86670',
                                    color: 'white'
                                },
                                [`.${gridClasses.cell}.cold`]: {
                                    backgroundColor: '#A0D1FF',
                                    color: '#1a3e72'
                                },
                                [`.${gridClasses.cell}.hot`]: {
                                    backgroundColor: '#FF6961',
                                    color: 'white',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center'
                                },
                                [`.${gridClasses.cell}.green`]: {
                                    backgroundColor: '#A5D6A7',
                                    color: '#1a3e72',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center'
                                },
                                height: `calc(100vh - 250px)`
                            }}
                            getRowHeight={() => 'auto'}
                            getCellClassName={(params) => {
                                if (params?.field === 'reprogramming' && params?.value != null && params?.value !== '-') {
                                    const reprogrammingDate = parseDate(params.value); // Converte `reprogramming`
                                    const dataEntregaDate = parseDate(params.row.data_entrega); // Converte `data_entrega`

                                    console.log('Reprogramming:', reprogrammingDate, 'Data Entrega:', dataEntregaDate);

                                    if (reprogrammingDate > dataEntregaDate) {
                                        return 'hot'; // Vermelho
                                    } else if (reprogrammingDate < dataEntregaDate) {
                                        return 'green'; // Verde
                                    }
                                }
                                if (
                                    params?.field === 'gepro' ||
                                    params?.field === 'pedido' ||
                                    params?.field === 'perc_exp' ||
                                    params?.field === 'perc_prod' ||
                                    params?.field === 'perc_prog'
                                ) {
                                    //Retornar cor azul clara
                                    return 'cold';
                                }
                                return '';
                            }}
                        />
                    )}
                </Box>
            </Grid>
        </MainCard>
    );
}
